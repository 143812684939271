import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ImageTriplet = makeShortcode("ImageTriplet");
const Video = makeShortcode("Video");
const ImageGrid = makeShortcode("ImageGrid");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Showcase Events`}</h1>
    <p>{`The Studio Collective organizes quarterly showcase events spanning live and electronic music. We highlight up-and-coming musicians from around the Bay Area, creating a foundation for artist growth and collaboration. Our events also include contributions from visual artists and chefs.`}</p>
    <h2>{`Studio Collective vol. 2`}</h2>
    <p>{`Our second showcase took place on 11.19.23, in collaboration with the Robot Heart Residency. The evening centered around a cross genre mix of live-electronic and vinyl.`}</p>
    <p><strong parentName="p">{`Musicians`}</strong>{` - `}<a parentName="p" {...{
        "href": "https://www.instagram.com/vivesmusica_/"
      }}>{`Vivés Musica`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.instagram.com/qjin_guzheng/"
      }}>{`QJin`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.instagram.com/rozihtmusic/"
      }}>{`Roziht Eve`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.instagram.com/toshbeats/"
      }}>{`Tōsh`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.instagram.com/nicholaspaul26/"
      }}>{`Nicholas Paul`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.instagram.com/luigisambuy/"
      }}>{`Luigi Sambuy`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.instagram.com/jondixon_/"
      }}>{`Jon Dixon`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.instagram.com/quislife/"
      }}>{`Marquis Johnson`}</a>{` `}<br />{`
`}<strong parentName="p">{`Selectors`}</strong>{` - `}<a parentName="p" {...{
        "href": "https://www.instagram.com/msrastad/"
      }}>{`DJ Dot`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.instagram.com/shafferj/"
      }}>{`Justin Shaffer`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.instagram.com/wang_can_hang/"
      }}>{`Vivian Wang`}</a>{` `}<br />{`
`}<strong parentName="p">{`Dancers`}</strong>{` - `}<a parentName="p" {...{
        "href": "https://www.instagram.com/postballet/"
      }}>{`Post:Ballet`}</a>{` `}<br />{`
`}<strong parentName="p">{`Chefs`}</strong>{` - `}<a parentName="p" {...{
        "href": "https://www.instagram.com/theveganhoodchefs/"
      }}>{`Vegan Hood Chefs`}</a></p>
    <ImageTriplet src1="https://res.cloudinary.com/studiocollective/image/upload/v1720506791/Studio%20Collective/Volume%202/Nada_wvgpj7.png" src2="https://res.cloudinary.com/studiocollective/image/upload/v1720506802/Studio%20Collective/Volume%202/Vros_kbwte2.png" src3="https://res.cloudinary.com/studiocollective/image/upload/v1720506790/Studio%20Collective/Volume%202/QJin_yw9ivi.png" mdxType="ImageTriplet" />
    <ImageTriplet src1="https://res.cloudinary.com/studiocollective/image/upload/v1720506801/Studio%20Collective/Volume%202/Roziht_qy8wcr.png" src2="https://res.cloudinary.com/studiocollective/image/upload/v1720506771/Studio%20Collective/Volume%202/Luigi_aprjno.png" src3="https://res.cloudinary.com/studiocollective/image/upload/v1720506802/Studio%20Collective/Volume%202/Tosh_x_Nick_du3sud.png" mdxType="ImageTriplet" />
    <ImageTriplet src1="https://res.cloudinary.com/studiocollective/image/upload/v1720506801/Studio%20Collective/Volume%202/Room_131_iv8sin.png" src2="https://res.cloudinary.com/studiocollective/image/upload/v1720506760/Studio%20Collective/Volume%202/Justin_x_Vivian_wpkstu.png" src3="https://res.cloudinary.com/studiocollective/image/upload/v1720506542/Studio%20Collective/Volume%202/Full_Lineup_2_ic9jta.png" mdxType="ImageTriplet" />
    <br /> 
    <br /> 
    <Video videoId="CRPxuyT7luc" alt="Roziht x Tōsh x Post:Ballet" mdxType="Video" />
    <h2>{`Studio Collective vol. 1`}</h2>
    <p>{`Our first showcase took place on 10.22.22, in celebration of Tōsh's 30th birthday. The evening began with live jazz and ended with vinyl selectors, featuring four debut peformances.`}</p>
    <p><strong parentName="p">{`Musicians`}</strong>{` - Imposter Syndrome, `}<a parentName="p" {...{
        "href": "https://www.instagram.com/mehdisiac/"
      }}>{`Mehdi Auorir`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.instagram.com/lwcassid/"
      }}>{`Lance Cassidy`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.instagram.com/vivesmusica_/"
      }}>{`Vivés Musica`}</a>{` `}<br />{`
`}<strong parentName="p">{`Selectors`}</strong>{` - `}<a parentName="p" {...{
        "href": "https://www.instagram.com/msrastad/"
      }}>{`DJ Dot`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.instagram.com/shafferj/"
      }}>{`Justin Shaffer`}</a>{` `}<br />{`
`}<strong parentName="p">{`Visual Artist`}</strong>{` - `}<a parentName="p" {...{
        "href": "https://www.instagram.com/oceane_jb/"
      }}>{`Oceane Briand`}</a>{` `}<br />{`
`}<strong parentName="p">{`Chefs`}</strong>{` - Darshna Desai, `}<a parentName="p" {...{
        "href": "https://www.instagram.com/jamesjjulius/"
      }}>{`James Darby`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.instagram.com/michael.raspuzzi/"
      }}>{`Michael Raspuzzi`}</a></p>
    {
      /* Image grid x2 of musicians */
    }
    <ImageGrid src1="https://res.cloudinary.com/studiocollective/image/upload/v1667444081/Studio%20Collective/Volume%201/DSC06055_tskwut.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1667444393/Studio%20Collective/Volume%201/DSC06048_kpydf9.jpg" src3="https://res.cloudinary.com/studiocollective/image/upload/v1667444392/Studio%20Collective/Volume%201/DSC06008_ig26ew.jpg" src4="https://res.cloudinary.com/studiocollective/image/upload/v1667444072/Studio%20Collective/Volume%201/DSC06075_l8mh5s.jpg" src5="https://res.cloudinary.com/studiocollective/image/upload/v1667444078/Studio%20Collective/Volume%201/DSC06070_yuumfz.jpg" src6="https://res.cloudinary.com/studiocollective/image/upload/v1667444066/Studio%20Collective/Volume%201/DSC06094_ccrxev.jpg" mdxType="ImageGrid" />
    <br /> 
    <br /> 
    {
      /* Image of program */
    }
    <img {...{
      "src": "https://res.cloudinary.com/studiocollective/image/upload/v1667514396/Studio%20Collective/Volume%201/Screen_Shot_2022-11-03_at_3.23.09_PM_y329by.png",
      "width": "100%"
    }}></img>
    {
      /* Images grid of decor */
    }
    <ImageTriplet src1="https://res.cloudinary.com/studiocollective/image/upload/v1667443951/Studio%20Collective/Volume%201/90B101D9-B721-4B8F-B8C8-996CD48409DB_qa9bf5.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1667444046/Studio%20Collective/Volume%201/D493CC1F-E0D4-4285-AACF-3E205764C654_ipvk8w.jpg" src3="https://res.cloudinary.com/studiocollective/image/upload/v1667444044/Studio%20Collective/Volume%201/C1F4854C-1F8C-48CA-A12C-20B231EC77CC_uvmtfu.jpg" mdxType="ImageTriplet" />
    {
      /* Images grid of music */
    }
    <ImageTriplet src1="https://res.cloudinary.com/studiocollective/image/upload/v1667444060/Studio%20Collective/Volume%201/CE8EF557-EC0A-4725-8160-27E4B7F4E996_uk1lxl.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1667443946/Studio%20Collective/Volume%201/5221CAAE-61E8-4DCD-9E13-99B9043F5CA4_djsonv.jpg" src3="https://res.cloudinary.com/studiocollective/image/upload/v1667443938/Studio%20Collective/Volume%201/43BFA488-247B-4B7C-991F-DEB43C1BA033_dkwnrt.jpg" mdxType="ImageTriplet" />
    {
      /* Image grid of program cover */
    }
    <ImageTriplet src1="https://res.cloudinary.com/studiocollective/image/upload/v1667514748/Studio%20Collective/Volume%201/Showcase_Flyer.013_w5gkfl.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1667444557/Studio%20Collective/Volume%201/Showcase_Flyer.001_gugexf.jpg" src3="https://res.cloudinary.com/studiocollective/image/upload/v1667514749/Studio%20Collective/Volume%201/Showcase_Flyer.011_gg2gtk.jpg" mdxType="ImageTriplet" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      